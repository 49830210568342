const HEADER_HEIGHT = 132

const observer = new window.IntersectionObserver(function ([entry]) {
  if (entry.isIntersecting) {
    const id = entry.target.id
    for (const navId in this.navs) {
      if (navId.includes(id)) {
        for (const el of this.navs[navId]) {
          el.classList?.add('nav__link--active')
        }
      } else {
        for (const el of this.navs[navId]) {
          el.classList?.remove('nav__link--active')
        }
      }
    }

    return
  }
}, {
  root: null,
  threshold: 0.2,
})

let throttled;
const throttle = (callback, time) => {
  if (throttled) return;
  throttled = true;

  setTimeout(() => {
    callback();
    throttled = false;
  }, time);
};

const headerStyleHandler = () => {
  if (window.pageYOffset > HEADER_HEIGHT) {
    if (window.pageYOffset < window.innerHeight) {
      header.classList.add('header--scrolled')
      header.classList.remove('header--scrolled-light')
    } else {
      header.classList.add('header--scrolled-light')
      header.classList.remove('header--scrolled')
    }
  } else {
    header.classList.remove('header--scrolled')
    header.classList.remove('header--scrolled-light')
  }
}

const main = () => {
  const header = document.getElementById('header');
  const main = document.getElementById('main');
  const networks = document.getElementById('networks');
  const services = document.getElementById('services');
  const about = document.getElementById('about');
  const contacts = document.getElementById('contacts');
  const sections = {
    main,
    networks,
    services,
    about,
    contacts,
  }

  observer.navs = {
    main: document.getElementsByClassName('link-to-main'),
    services: document.getElementsByClassName('link-to-services'),
    networks: document.getElementsByClassName('link-to-networks'),
    about: document.getElementsByClassName('link-to-about'),
    contacts: document.getElementsByClassName('link-to-contacts'),
  }

  document.getElementById('scroll-more').addEventListener('click', (e) => {
    sections.networks.scrollIntoView({
      behavior: "smooth",
    })
  })

  document.getElementById('burger-open').addEventListener('click', (e) => {
    header.classList.toggle('header--open')
  })

  const contactsBtns = document.getElementsByClassName('show-form-btn')

  for (const btn of contactsBtns) {
    btn.addEventListener('click', (e) => {
      sections.contacts.scrollIntoView({
        behavior: "smooth",
      })
    })
  }

  for (const nav in observer.navs) {
    for (const el of observer.navs[nav]) {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        header.classList.remove('header--open')
        sections[nav].scrollIntoView({
          behavior: "smooth",
        })
      })
    }
  }

  observer.observe(main);
  observer.observe(networks);
  observer.observe(services);
  observer.observe(about);
  observer.observe(contacts);

  const form = document.getElementById('contact-form')
  const formResponse = document.getElementById('contact-form-response')

  form.addEventListener('submit', (e) => {
    e.preventDefault()
    const formData = new FormData(form);

    const headers = new Headers()
    headers.set('api-key', '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')
    headers.set('accept', 'application/json')
    headers.set('content-type', 'application/json')
    const body = {
      sender: {
        name: 'Landing Crouton',
        email: 'no-reply@crouton.digital',
      },
      to: [{
        name: 'Nodes Info',
        email: 'nodes@ecng.io',
      }],
      subject: `Landing Crouton contact us form`,
      htmlContent: `
        <html><head></head><body>
          <p>name: ${formData.get('name')}</p>
          <p>email: ${formData.get('email')}</p>
          <p>message: ${formData.get('message')}</p>
        </body></html>
      `
    }

    fetch('https://api.brevo.com/v3/smtp/email', {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    }).then(response => {
      if (response.status === 200 || response.status === 201) {
        form.reset()
        form.classList.add('success')
      } else {
        formResponse.innerHTML = 'Something went wrong. Please try again later.'
        form.classList.add('success')
      }
    })
  })

  window.addEventListener('load', headerStyleHandler)
  window.addEventListener('scroll', () => throttle(headerStyleHandler, 200))

  if (window.innerWidth <= 768) {
    tns({
      container: '.team',
      fixedWidth: 322,
      nested: "inner",
      edgePadding: 20,
      mouseDrag: true,
      loop: true,
      slideBy: "page",
      swipeAngle: false,
      gutter: 20,
      autoplay: true,
      autoplayHoverPause: true,
      speed: 400,
      nav: false,
      autoplayButton: false,
      controls: false,
      autoplayButtonOutput: false,
    });
  }
}

window.addEventListener("DOMContentLoaded", main);
